.description {
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  margin-bottom: 1.5rem;
}

.description_box {
  border: 1px solid rgba(208, 210, 218, 1);
  border-radius: 4px;
}

.title_button {
  display: flex;
  align-items: center;
}

.showContent {
  position: absolute;
  bottom: 0;
  padding: 0.5rem 0;
  text-align: center;
  width: 100%;
  height: 5vh;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  font-family: "Rubik", sans-serif;
  font-size: 0.9rem;
}
